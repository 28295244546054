
div#react-doc-viewer {
    min-height: 100vh;
}
figure.image.image_resized > img {
    width: 100%;
}
figure > img {
    width: 100%;
}

.ck-custom > .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, 
.ck-custom > div >.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners,
.ck-custom > div >.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: 0;
}