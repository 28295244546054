


#section-testimonials {
	padding: 120px 20px 60px 20px;
	background-image: radial-gradient(#384b93, #384b93, #384b93); 
}

@media (min-width: 660px) {
		#section-testimonials 
		{padding: 120px 30px 120px 30px; } 
}

@media (min-width: 1200px) {
    #section-testimonials
	{padding: 120px 60px 120px 60px; } 
}

@media (min-width: 660px) {
    #section-testimonials {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='100' viewBox='0 0 1600 100'%3E%3Cpath fill='%23FFF' d='M800 100 0 0 1600 0Z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='100' viewBox='0 0 1600 100'%3E%3Cpath fill='%23FFF' d='M800 0 0 100 1600 100Z'/%3E%3C/svg%3E"), radial-gradient(#384b93, #384b93, #384b93);
      background-repeat: no-repeat;
      background-size: 100% 100px, 100% 100px, 100%;
      background-position: top center, bottom center, 50%; } 
}

