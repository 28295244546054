

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "video.js/dist/video-js.css";
@import "react-slideshow-image/dist/styles.css";
/* rgb(187 185 185) */
@font-face {
	font-family: 'MyCustomFont';
	src: url('./assets/FAIRE-Sprig-Trial-Light.woff2') format('truetype');
	font-weight: normal;
	font-style: normal;
  }

  @font-face {
	font-family: 'boldFont';
	src: url('./assets/FontsFree-Net-b8a5db82-ba39-4dfa-a724-55701f47cac9.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }

body{  
	height: 100%;
    margin: 0px !important;
	font-family: 'MyCustomFont', sans-serif;
}

video{height:inherit;border-radius:inherit;object-fit: cover;}
div[role*="tabpanel"] > div:first-child {padding:0px}
.fc-license-message{display:none}
.fc .fc-daygrid-day.fc-day-today {
    background-color: #e0f3ff;
}  
.react-player {
  position: absolute;
  top: 0;
  left: 0;    
  border-radius:0px;
}
.rcw-conversation-container .rcw-header , .rcw-launcher  {background-color: #25387c;}
iframe {border-width: 0px !important;}

.react-multiple-carousel__arrow {
   background:#25387c ;
}
.react-multiple-carousel__arrow:hover {
    background:#25387cd4 ;
}
div[role="tooltip"]>div{
	font-weight: bold;
	font-size: .875rem;
	background-color:#6d6d6dc9;
}
/* div[role="tooltip"]>div{
	font-size: .875rem;
	background-image: linear-gradient(to right, #fdfdfd, #fdfdfd, #fdfdfe, #fcfdfe, #fcfdff); 
	border: 1px solid #b8bdc3;
}
 div[role="tooltip"]>div{
    padding: 8px ;
    border-radius: 25px;
	text-align:center;
	font-size:15px;
	font-weight:600;color: #25387c;
	background-color:transparent;
}
color: #f9bb02;color: #f9bb02;
box-shadow: rgb(255 193 7 / 24%) 0px 8px 16px 0px;
*/



 /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
             .content-div {
				margin-top: 4% !important
			}
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 767px) {
            
			.content-div {
				margin-top: 5% !important
			}
        }
          
        /* Media Query for Tablets Ipads portrait mode */
        @media (min-width: 768px) and (max-width: 1024px){
			.content-div {
				margin-top: 10% !important;
			}       
		}
          
        /* Media Query for Laptops and Desktops */
        @media (min-width: 1025px) and (max-width: 1280px){
            .content-div {
				margin-top: 10% !important;
			}
        }
          
        /* Media Query for Large screens */
        @media (min-width: 1281px) {
           .content-div {
				margin-top: calc(100vh - 38%) !important;
			}
        }
@media (min-width: 481px) {
.res-text{
	font-size:20px
}
}

ol
{
	margin-left:40px
}
.community-subheader
{
	margin-bottom:16px;
}